/* eslint-disable react/no-danger */
import React from "react";
import Spacer from "../../../../../Spacer/Spacer";
import ArticleHeader from "../ArticleHeader/ArticleHeader";
import ArticlePromoPanel from "../ArticlePromoPanel/ArticlePromoPanel";
import ArticleAuthorPanel from "../ArticleAuthorPanel/ArticleAuthorPanel";
import "./ArticleContent.scss";

const ArticleContent = ({ title, content, date, author, categories }) => {
  return (
    <div className="container">
      <Spacer size={50} mobileSize={50} />
      <div className="row">
        <div className="col-12 col-lg-8">
          <ArticleHeader
            title={title}
            date={date}
            author={author}
            categories={categories}
          />
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <ArticleAuthorPanel author={author} />
        </div>
        <div className="col-12 col-lg-4">
          <ArticlePromoPanel />
        </div>
      </div>
      <Spacer size={150} mobileSize={100} />
    </div>
  );
};

export default ArticleContent;
