import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./FeaturedImage.scss";

const FeaturedImage = ({ image, title }) => {
  return image ? (
    <div className="featuredImage">
      <GatsbyImage
        image={image.node.localFile.childImageSharp.gatsbyImageData || ""}
        alt={`${title}`}
      />
    </div>
  ) : null;
};

export default FeaturedImage;
