import React from "react";
import "./ArticleAuthorPanel.scss";

const ArticleAuthorPanel = ({ author }) => {
  return (
    author.node.description && (
      <div className="article-author-panel">
        <h4>About the author</h4>
        <p>{author.node.description}</p>
      </div>
    )
  );
};

export default ArticleAuthorPanel;
