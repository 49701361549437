import React from "react";
import FeaturedImage from "./components/FeaturedImage/FeaturedImage";
import ArticleContent from "./components/ArticleContent/ArticleContent";
import "./NewsPost.scss";

const NewsPost = ({ title, image, content, date, author, categories }) => {
  return (
    <article>
      <FeaturedImage image={image} title={title} />
      <ArticleContent
        title={title}
        content={content}
        date={date}
        author={author}
        categories={categories}
      />
    </article>
  );
};

export default NewsPost;
