import React from "react";
import "./ArticlePromoPanel.scss";

const ArticlePromoPanel = () => {
  return (
    <div className="article-promo-panel">
      <p>Promotional panel</p>
    </div>
  );
};

export default ArticlePromoPanel;
