import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import NewsPost from "../components/News/components/NewsPost/NewsPost";
import Footer from "../components/Footer/Footer";

const NewsPostTemplate = ({ data: { news } }) => {
  const {
    newsSinglePost: { publicationTitle },
    newsCategories: { nodes: categories },
    featuredImage,
    content,
    date,
    author,
    title,
  } = news;
  //console.log(news);
  return (
    <>
      <Seo
        image={featuredImage}
        title={title || publicationTitle}
        content={content}
        date={date}
        author={author}
        categories={categories}
      />
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
      />
      <Layout>
        <Header />
        <NewsPost
          image={featuredImage}
          title={title}
          content={content}
          date={date}
          author={author}
          categories={categories}
        />
        <Footer />
      </Layout>
    </>
  );
};

export default NewsPostTemplate;

export const pageQuery = graphql`
  query NewsPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current news by id
    news: wpNews(id: { eq: $id }) {
      id
      content
      newsSinglePost {
        publicationTitle
      }
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                height: 302
                width: 1920
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          altText
        }
      }
      uri
      seo {
        canonical
        cornerstone
        focuskw
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterTitle
        schema {
          raw
        }
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        opengraphImage {
          localFile {
            childImageSharp {
              fluid {
                base64
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      }
      newsCategories {
        nodes {
          name
          uri
        }
      }
      author {
        node {
          firstName
          lastName
          nicename
          nickname
          uri
          description
        }
      }
    }
  }
`;
